import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  // 标签
  {
    path: "/",
    name: "editor",
    component: () => import("@/views/editor.vue")
  },
  // 标签列表
  {
    path: "/list",
    name: "list",
    component: () => import("@/views/list.vue")
  },
  // 在线填表
  {
    path: "/excelOnline",
    name: "excelOnline",
    component: () => import("@/views/excelOnline.vue")
  },
  // 上传表格
  {
    path: "/excelUpload",
    name: "excelUpload",
    component: () => import("@/views/excelUpload.vue")
  },
  // 下载结果
  {
    path: "/result",
    name: "result",
    component: () => import("@/views/result.vue")
  },
];

const router = new VueRouter({
  mode: 'history',
  // base: '/',
  routes,
});

export default router;
